<template>
  <b-modal
    size="lg"
    id="new-member-modal"
    @shown="updateEditing"
    @hidden="hiddenEvent"
  >
    <template #modal-header="{ close }">
      <h5 class="modal-title" v-if="editing">Edit Member</h5>
      <h5 class="modal-title" v-else>Add Member</h5>
      <button type="button" class="btn-close" @click.prevent="close()"></button>
    </template>

    <div class="modal-content" data-v-step="10">
      <div class="row">
        <div class="col-5">
          <div class="mb-3">
            <label class="form-label">Field Name</label>
          </div>
        </div>
        <div class="col-5">
          <div class="mb-3">
            <label class="form-label">Field Value</label>
          </div>
        </div>
      </div>
      <div
        class="row"
        v-for="field in Object.keys(member)"
        :key="field"
        data-v-step="11"
      >
        <div class="col-5">
          <div class="mb-3">
            <input
              id="name"
              type="text"
              :class="['form-control', 'disabled']"
              autocomplete="off"
              :value="field"
              disabled
            />
          </div>
        </div>
        <div class="col-5">
          <div class="mb-3">
            <input
              id="name"
              type="text"
              :class="[
                'form-control',
                $v.member[field].$error ? 'is-invalid' : '',
                editing && field == 'email' ? 'disabled' : '',
              ]"
              autocomplete="off"
              :disabled="editing && field == 'email'"
              v-model="$v.member[field].$model"
            />
            <div class="invalid-feedback" v-if="!$v.member[field].required">
              Member <code>{{ field }}</code> is required.
            </div>
            <div
              class="invalid-feedback"
              v-if="
                $v.member[field].email !== undefined &&
                $v.member[field].email === false
              "
            >
              Please enter a valid email address.
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
      <div data-v-step="12">
        <div
          class="row"
          v-for="(v, index) in $v.additional_fields.$each.$iter"
          :key="index"
        >
          <div class="col-5">
            <div class="mb-3">
              <input
                id="name"
                type="text"
                :class="[
                  'form-control',
                  v.field_name.$error ? 'is-invalid' : '',
                ]"
                autocomplete="off"
                v-model.trim="v.field_name.$model"
              />

              <div class="invalid-feedback" v-if="!v.field_name.required">
                Field name is required.
              </div>
              <div class="invalid-feedback" v-if="!v.field_name.slug">
                Field name can only have the characters <code>a-z</code>,
                <code>0-9</code>, and <code>_</code>.
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="mb-3">
              <input
                id="name"
                type="text"
                :class="[
                  'form-control',
                  v.field_value.$error ? 'is-invalid' : '',
                ]"
                autocomplete="off"
                v-model="v.field_value.$model"
              />

              <div class="invalid-feedback" v-if="!v.field_value.required">
                Field data is required.
              </div>
            </div>
          </div>
          <div class="col-2">
            <button class="btn" href="#" @click.prevent="removeField(index)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon me-0 ms-0 icon-tabler icon-tabler-trash"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="4" y1="7" x2="20" y2="7"></line>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div>
              <button
                class="btn btn-outline-primart mb-1"
                @click.prevent="addField"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-circle-plus"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="12" cy="12" r="9"></circle>
                  <line x1="9" y1="12" x2="15" y2="12"></line>
                  <line x1="12" y1="9" x2="12" y2="15"></line>
                </svg>
                Add additional field
              </button>
              <div class="form-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-bulb"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7"
                  ></path>
                  <path
                    d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3"
                  ></path>
                  <line x1="9.7" y1="17" x2="14.3" y2="17"></line>
                </svg>
                Add additional fields to your cohort member to use these fields
                in templates and for reporting.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <div class="d-flex flex-grow-1" data-v-step="13">
        <a
          href="#"
          @click.prevent="
            reset();
            cancel();
          "
          class="btn btn-link link-secondary"
          data-bs-dismiss="modal"
        >
          Cancel
        </a>
        <a
          href="#"
          v-if="editing"
          class="btn btn-outline-danger ms-auto me-2"
          @click="deleteMember(ok)"
        >
          Delete Member
        </a>
        <a
          href="#"
          :disabled="$v.$invalid"
          @click.prevent="save(ok)"
          :class="[
            'btn',
            'btn-primary',
            editing ? '' : 'ms-auto',
            $v.$invalid ? 'disabled' : '',
          ]"
          v-if="!saving"
        >
          <!-- Download SVG icon from http://tabler-icons.io/i/plus -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          <span v-if="editing">Save member</span>
          <span v-else>Add new member</span>
        </a>
        <a
          href="#"
          :class="['btn', 'btn-primary', editing ? '' : 'ms-auto', 'disabled']"
          @click.prevent
          v-else
        >
          <span
            class="spinner-border spinner-border-sm me-2"
            role="status"
          ></span>
          Saving
        </a>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required, email, helpers } from "vuelidate/lib/validators";
import ReputationManagementService from "../../../services/ReputationManagementService";
import TrackingService from "@/services/TrackingService";

const slug = helpers.regex("slug", /^[a-z0-9_]+$/);

export default {
  name: "reputation.cohort.add-member-modal",
  props: {
    cohort: Object,
    editing: Object,
    tutorial: Boolean,
  },
  data: () => {
    return {
      member: {
        first_name: "",
        last_name: "",
        email: "",
        company_name: "",
      },
      additional_fields: [],
      additionalFieldId: 0,
      saving: false,
    };
  },
  methods: {
    reset() {
      this.member = {
        first_name: "",
        last_name: "",
        email: "",
        company_name: "",
      };
      this.additional_fields.splice(0, this.additional_fields.length);
      this.additionalFieldId = 0;
      this.$v.$reset();
      this.saving = false;
    },
    async deleteMember(ok) {
      let confirm = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete ${this.member.first_name} from your survey group? You cannot undo this action.`,
        {
          title: `Permanently Delete Member?`,
          size: "sm",
          okVariant: "outline-danger",
          okTitle: "Delete",
          cancelClasses: "me-auto",
          cancelTitle: "Cancel",
          cancelVariant: "primary",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      );
      if (confirm) {
        let member = { id: this.editing.id };
        await ReputationManagementService.deleteCohortMember(
          this.$auth.activeCompany,
          this.cohort.id,
          member
        );
        this.$emit("saved-member", member);
        ok();
        TrackingService.track("delete member");
      }
    },
    async save(ok) {
      if (!this.$v.$invalid) {
        this.saving = true;
        let member = {
          data: {
            first_name: this.member.first_name,
            last_name: this.member.last_name,
            email: this.member.email,
            company_name: this.member.company_name,
          },
        };
        this.additional_fields.forEach((v) => {
          member.data[v.field_name] = v.field_value;
        });
        try {
          if (this.editing) {
            TrackingService.track("edited member");
            member.id = this.editing.id;
            await ReputationManagementService.updateCohortMember(
              this.$auth.activeCompany,
              this.cohort.id,
              member
            );
          } else {
            TrackingService.track("added member");
            if (this.cohort) {
              await ReputationManagementService.addCohortMember(
                this.$auth.activeCompany,
                this.cohort.id,
                member
              );
            } else {
              this.$emit("saved-member", member);
              this.reset();
              ok();
            }
          }
        } catch (e) {
          this.saving = false;
          return;
        }
        this.$emit("saved-member");
        this.reset();
        ok();
      }
    },
    removeField(index) {
      TrackingService.track("remove member custom field");

      this.additional_fields.splice(index, 1);
    },
    addField() {
      TrackingService.track("added member custom field");

      this.additionalFieldId += 1;
      this.additional_fields.push({
        id: this.additionalFieldId,
        field_name: "",
        field_value: "",
      });
    },
    updateEditing() {
      if (this.editing) {
        let member = JSON.parse(JSON.stringify(this.editing.data));
        console.log("updating", member);

        this.member.first_name = member.first_name;
        this.member.last_name = member.last_name;
        this.member.email = member.email;
        this.member.company_name = member.company_name;
        Object.keys(this.member).map((k) => delete member[k]);
        Object.keys(member).forEach((k) => {
          this.additional_fields.push({
            field_name: k,
            field_value: member[k],
          });
        });
      }
      this.shownEvent();
    },
    shownEvent() {
      if (this.tutorial) {
        this.$nextTick(() => {
          this.$tours["getting_started_tutorial"].start(9);
          this.$tours["getting_started_tutorial"].previousStep();
          this.$tours["getting_started_tutorial"].nextStep();
        });
      }
    },
    hiddenEvent() {
      if (this.tutorial) {
        this.$tours["getting_started_tutorial"].start(13);
      }
    },
  },
  validations: {
    member: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      company_name: {
        required,
      },
    },
    additional_fields: {
      $each: {
        field_name: {
          required,
          slug,
        },
        field_value: {
          required,
        },
      },
    },
  },
};
</script>
